<template>
  <div class="page-box">
    <a-spin :spinning="loading">
      <div class="goods-detail-box">
        <div class="goods-detail">
          <div class="goods-info">
            <div class="img-info" v-if='productDetail.picList.length'>
              <base-img
                v-if='productDetail.picList[picActive].type !== 1'
                :src="productDetail.picList[picActive].picPath"
                width="240"
                height="240"
                oss_style="240_240"
              />
              <video class='big-video' v-else controls :src="baseUrl + productDetail.picList[picActive].picPath">
                当前浏览器不支持 video直接播放，点击这里下载视频： <a :href="baseUrl + productDetail.picList[picActive].picPath" target="_blank">下载视频</a>
              </video>
              <swiper ref="swiperRef" class="img-swiper" :options="swiperOptions">
                <swiper-slide
                  v-for="(picItem, i) in productDetail.picList"
                  :key="i" :class="{active: i === picActive}"
                  @click.native="picActive = i"
                >
                  <base-img
                    v-if='picItem.type !== 1'
                    :src="picItem.picPath"
                    width="46"
                    height="46"
                    oss_style="50_50"
                  />
                  <video class='small-video' v-else :src="baseUrl + picItem.picPath">
                    当前浏览器不支持 video直接播放，点击这里下载视频： <a :href="baseUrl + picItem.picPath" target="_blank">下载视频</a>
                  </video>
                </swiper-slide>
                <template v-if="productDetail.picList > 4">
                  <div
                    class="swiper-btn btn-prev"
                    slot="button-prev"
                    @click="swiperControl('prev')"
                  >
                    <a-icon type="caret-left" />
                  </div>
                  <div
                    class="swiper-btn btn-next"
                    slot="button-next"
                    @click="swiperControl('next')"
                  >
                    <a-icon type="caret-right" />
                  </div>
                </template>
              </swiper>
            </div>
            <div class="info-info">
              <h3 class="goods-name">{{productDetail.name}}</h3>
              <div class="info-box spec">
                <div class="info-item">
                  <span class="label">成本价</span>
                  <p class="value">
                    <span>{{productDetail.minTradePrice === productDetail.maxTradePrice || !productDetail.maxTradePrice ? `¥${productDetail.minTradePrice}` : `¥${productDetail.minTradePrice} - ¥${productDetail.maxTradePrice}`}}</span>
                  </p>
                </div>
                <div class="info-item">
                  <span class="label">利润率</span>
                  <p class="value warn">
                    <span>{{productDetail.minProfit === productDetail.maxProfit || !productDetail.maxProfit ? `${productDetail.minProfit}%` : `${productDetail.minProfit}% - ${productDetail.maxProfit}%`}}</span>
                  </p>
                </div>
                <div class="info-item">
                  <span class="label">参考零售价</span>
                  <span class="value">{{productDetail.minPrice === productDetail.maxPrice || !productDetail.maxPrice ? `¥${productDetail.minPrice}` : `¥${productDetail.minPrice} - ¥${productDetail.maxPrice}`}}</span>
                </div>
              </div>
              <div class="info-box" v-if="productDetail.skuList">
                <div class="info-item">
                  <span class="label">规格</span>
                  <div class="value">
                    <p v-for="(item, i) in productDetail.skuList" :key="i" style="margin-bottom: 2px">
                      <span>{{item.name}}</span>
                      <span>￥{{item.price}}</span>
                      <span>{{item.stock}}可售</span>
                    </p>
                  </div>
                </div>
              </div>
              <p class="other-info">
                <span>运费：{{logisticsUnifyPrice}}</span>
                <span>总销量：{{productDetail.sales}}</span>
                <span>总库存：{{productDetail.stock}}</span>
              </p>
              <div class="btn-box">
                <a-button
                  type="primary"
                  @click="handleCollect"
                  :disabled="!collectedState"
                >
                  上架到店铺
                </a-button>
              </div>
            </div>
          </div>
          <div class="sub-title">商品详情</div>
          <div class="rich-text" v-if="productDetail.detail" v-html="productDetail.detail"></div>
          <div v-else class="custom-empty">
            <a-empty />
          </div>
        </div>
        <div class="recommend">
          <div class="recommend-content">
            <div class="sub-title">其他推荐</div>
            <div class="recommend-list custom-goods-list">
              <template v-if="recommend.length">
                <div class="goods-item-box" v-for="item in recommend" :key="item.productId">
                  <div class="goods-item">
                    <div class="item-info-box" @click="handleDetail(item)">
                      <div class="goods-img rect-100">
                        <base-img
                          class="full"
                          :src="item.picPath"
                          width="150"
                          height="150"
                          oss_style="150_150"
                        />
                      </div>
                      <p class="goods-name el2">{{item.name}}</p>
                      <div class="goods-info">
                        <div>
                          <p>成本价：¥{{item.tradePrice}}</p>
                          <p>利润率：<span class="mark">{{item.profitMargin}}%</span></p>
                        </div>
                        <a href="javascript:;" class="btn-collect">采集</a>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else class="custom-empty">
                <a-empty />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from '@vue/composition-api'
import { goods } from '@/api'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'

export default {
  name: 'PageGoodsCollectDetail',
  props: {
    params: String // 包含productId及shopId, shopId有数据表示店铺商品列表从进入，shopId没数据表示从采集市场进入
  },

  setup (props, { root, emit }) {
    const propParams = computed(() => props.params ? JSON.parse(window.atob(props.params)) : {})
    const loading = ref(false)
    const productDetail = ref({
      picList: [],
      minTradePrice: '',
      maxTradePrice: '',
      minProfit: '',
      maxProfit: '',
      minPrice: '',
      maxPrice: ''
    })
    const logisticsUnifyPrice = computed(() => {
      const { logisticsUnifyPrice, maxFirstPrice, minFirstPrice } = productDetail.value
      if (typeof minFirstPrice !== 'undefined' && typeof maxFirstPrice !== 'undefined') {
        if (minFirstPrice !== maxFirstPrice) return `¥${minFirstPrice} - ¥${maxFirstPrice}`
        return `¥${minFirstPrice}`
      }
      if (typeof logisticsUnifyPrice !== 'undefined') return logisticsUnifyPrice === 0 ? '免运费' : `¥${logisticsUnifyPrice}`
      return ''
    })
    const recommend = ref([])
    const collectedState = ref(false)
    const picActive = ref(0)
    async function getDetail () {
      loading.value = true
      const { productId, upShopId } = propParams.value
      const { code, msg, data } = await goods.getBeCollectedProductDetail({
        productId,
        shopId: getSession(SHOPID),
        upShopId: upShopId,
        source: upShopId ? '2' : '1'
      })
      loading.value = false
      if (code === '00000') {
        productDetail.value = data.productDetail || {}
        recommend.value = data.otherProduct || []
        collectedState.value = data.collectedState
      } else {
        root.$message.error(msg || '详情获取失败，请稍后重试')
      }
    }
    onUnmounted(() => {
      root.$bus.$off('CollectDetail:refresh', callback)
    })
    onMounted(() => {
      getDetail()
      root.$bus.$on('CollectDetail:refresh', callback)
    })
    const callback = () => {
      getDetail()
    }
    const swiperRef = ref(null)
    const swiperOptions = ref({
      slidesPerView: 4,
      spaceBetween: 10
    })
    function swiperControl (type) {
      if (type === 'prev') {
        swiperRef.value.$swiper.slidePrev()
      } else {
        swiperRef.value.$swiper.slideNext()
      }
    }

    function handleCollect () {
      const params = {
        collectProductId: propParams.value.productId
      }
      root.$router.push(`/goods/publish/collect/${window.btoa(JSON.stringify(params))}`)
    }

    function handleDetail ({ productId, shopId }) {
      root.$router.push(`/goods/goods_collect_detail/${window.btoa(JSON.stringify({ productId, upShopId: shopId }))}`)
    }

    return {
      baseUrl: process.env.VUE_APP_IMG,
      loading,
      productDetail,
      logisticsUnifyPrice,
      recommend,
      collectedState,
      picActive,
      swiperRef,
      swiperOptions,
      swiperControl,
      handleCollect,
      handleDetail
    }
  }
}
</script>

<style lang="less" scoped>
.goods-detail-box {
  display: flex;
  justify-content: space-between;
  width: 830px;
  margin: 0 auto;
}
.goods-detail {
  flex: none;
  width: 630px;
}
.goods-info {
  display: flex;
  justify-content: space-between;
  .img-info {
    flex: none;
    width: 240px;
    .img-swiper {
      margin: 10px 0;
      ::v-deep .swiper-slide {
        padding: 2px;
        border: 1px solid #fff;
        cursor: pointer;
        &:hover,
        &.active {
          border-color: #eee;
        }
      }
      .swiper-btn {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 10;
        width: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #808080;
        cursor: pointer;
        font-size: 13px;
        &:hover {
          background-color: rgba(0, 0, 0, .1);
        }
      }
      .btn-prev {
        left: 0;
      }
      .btn-next {
        right: 0;
      }
    }
  }
  .info-info {
    width: 374px;
    .goods-name {
      min-height: 44px;
      font-size: 14px;
      line-height: 22px;
      color: #333;
    }
    .info-box {
      max-height: 200px;
      padding: 10px 10px 10px 0;
      border-bottom: 1px solid #DCDFE6;
      font-size: 12px;
      color: #666;
      line-height: 20px;
      overflow-y: auto;
      &.spec {
        background-color: #F3F6F8;
      }
      .warn {
        color: #E83828;
      }
      .info-item {
        display: flex;
        .label {
          flex: none;
          width: 68px;
          margin-right: 22px;
          text-align: right;
        }
        .value span {
          margin-right: 10px;
          &:last-child {
            margin: 0;
          }
        }
        .icon-tax {
          font-size: 14px;
          .warn;
        }
      }
    }
    .other-info {
      padding: 4px 0;
      font-size: 12px;
      line-height: 20px;
      span {
        margin-right: 10px;
        &:last-child {
          margin: 0;
        }
      }
    }
    .btn-box {
      display: flex;
      margin-top: 30px;
      ::v-deep .ant-btn {
        margin-right: 6px;
        &:last-child {
          margin-right: 0;
        }
      }
      .icon-table-tip {
        color: #cacaca;
      }
    }
  }
}
.sub-title {
  margin-top: 26px;
  padding: 0 16px;
  font-size: 16px;
  color: #333;
  line-height: 48px;
  background-color: #F3F6F8;
}
.rich-text {
  margin-top: 10px;
  ::v-deep img {
    max-width: 100%;
  }
}
.recommend {
  flex: none;
  width: 190px;
  .recommend-content {
    border: 1px solid #DCDFE6;
    .recommend-list {
      padding-top: 12px;
    }
    .goods-item-box {
      float: none;
      width: 100%;
      &:last-child .goods-item {
        border: none;
      }
    }
    .sub-title {
      margin: 0;
    }
    .goods-item {
      margin: 0 auto 12px;
      padding-bottom: 8px;
      border-bottom: 1px solid #DCDFE6;
    }
  }
}
.custom-empty {
  padding: 24px 0;
  ::v-deep .ant-empty-image svg {
    width: auto;
  }
}
.big-video {
  width: 240px;
  height: 240px;
  object-fit: contain;
}
.small-video {
  width: 46px;
  height: 46px;
  object-fit: contain;
}
</style>
